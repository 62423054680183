import { format } from "date-fns";
import { graphql } from "../services/wp.api.service";

const query = `
query AnnouncementsQuery($now: String!, $role: [String]!) {
	announcementFacet(
	  where: {query: {announcement_start_time: {max: $now}, announcement_roles: $role, announcement_end_time: {min: $now}}}
	) {
	  announcements(first: 5) {
		nodes {
		  content
		  title
		  databaseId
		}
	  }
	}
  }
`;

const now = format(new Date(), "yyyy-MM-dd");

// eslint-disable-next-line max-params
export const getAnnouncementQuery = (useQuery, role, notIn = [], options = {}) => useQuery(
	["siteAnnouncement"],
	role ? () => graphql({
		query,
		variables: {
			now,
			role
		}
	}).
		then(({ data }) => {
			if (data.errors) {
				return null;
			}

			if (data.data.announcementFacet.announcements?.nodes?.length) {
				const announcements = data.data.announcementFacet.announcements.nodes.
					filter(({ databaseId }) => notIn.indexOf(databaseId) === -1);
				return announcements.length > 0 ? announcements[0] : null;
			}

			return null;
		}) : null,
	{
		staleTime: 10 * 60 * 1000,
		...options
	}
);
