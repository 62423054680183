import { Col, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import { Outlet } from "react-router-dom";
import ProfileNav from "./ProfileNav";

const Profile = () => (
	<Row as="main" className="profile | flex-nowrap bg-eqqo-blue-white">
		<Helmet>
			<title>{"EQQO Profile"}</title>
		</Helmet>

		<Col className="profile__sidebar bg-secondary" lg={3}>
			<ProfileNav />
		</Col>
		<Col className="profile__content" lg={9}>
			<div className="align-items-center border-bottom d-flex flex-nowrap justify-content-between px-0 py-4">
				<h1 className="mb-0">EQQO Profile</h1>
				<a href="/profile/account">My Account</a>
			</div>
			<div>
				<Outlet />
			</div>
		</Col>
	</Row>
);

export default Profile;
