/* eslint-disable max-len */

import { Button, Col, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import ImagePlaceholder from "../../icons/ImagePlaceholder";

const SignUp = () => (
	<Row as="main" className="sign-up cover">
		<Helmet>
			<title>
				{"Sign Up for EQQO"}
			</title>
		</Helmet>

		<Col className="sign-up-content flow" md={7} xs={12}>
			<img
				alt="LOGO" className="logo" height="704" src="/eqqo_logo.png"
				width="703"
			/>
			<h1>Sign Up For EQQO</h1>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit ut</p>
			<Button className="btn-gradient" href="/sign-up/account" >Get Started</Button>
		</Col>
		<Col className="sign-up-decoration" md={5} xs={12}>
			<ImagePlaceholder />
		</Col>
	</Row>
);

export default SignUp;
