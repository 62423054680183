/* eslint-disable max-statements */
/* eslint-disable no-mixed-operators */
import { authHeader, revalidateToken, shouldRevalidateToken } from "./wp.auth.service";
import axios, { CancelToken } from "axios";

/**
 * The API Base
 */
const API_URL = process.env.REACT_APP_API_URL || ""; /* eslint-disable-line no-undef */

/**
 * The Axios Client Instance
 */
const client = axios.create({ baseURL: API_URL });

/**
 * A GraphQL Post Query Wrapper
 *
 * @param {query, variables} args The arguments to pass to the GraphQL server;
 * @returns
 */
export const graphql = async (data, noHeaders = false) => {

	if (!noHeaders && shouldRevalidateToken()) {
		const canRevalidate = await revalidateToken();
		if (!canRevalidate) {
			localStorage.removeItem("user");
			localStorage.removeItem("jwtAuthExpiration");
		}
	}

	const source = CancelToken.source();

	if (data.query) {
		data.query = data.query.replace(/\t/gu, "");
	}

	const promise = client.post(
		"/graphql",
		data,
		{
			cancelToken: source.token,
			headers: noHeaders ? {} : authHeader()
		}
	).
		then((res) => {
			if (res.data.errors && res.data.errors[0]) {
				const { errors } = res.data;
				throw new Error(errors[0].message);
			}
			return res;
		});

	promise.cancel = () => {
		source.cancel("Query cancelled by React Query");
	};

	return promise;
};
