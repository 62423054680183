/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Button, Col, Image, Row } from "react-bootstrap";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Helmet from "react-helmet";
import wave from "../assets/eqqo-wave.png";

const ReportLanding = () => (
	<Row as="main" className="profile profile__landing | bg-white flex-dir-col pt-4">
		<Helmet>
			<title>
				{"EQQO Profile Report"}
			</title>
		</Helmet>
		<Col className="align-items-center d-flex flex-column gap-4">
			<Image className="mt-4" roundedCircle src={wave} />
			<h1 className="fw-bolder px-4 text-center">Your EQQO Profile Report</h1>
			<p className="gradient-text my-4 text-center">Emotional Quotient Questionnaire & Overview Profile</p>
			<Button className="mt-4 rounded-pill" href="/report" variant="gradient">View Your Report <ArrowRightIcon height={24} width={24} /></Button>
		</Col>
	</Row>
);

export default ReportLanding;
