/* eslint-disable max-len, indent, init-declarations, arrow-body-style, react/jsx-indent, react/jsx-indent-props */
import PropTypes from "prop-types";
import anger from "../assets/icons/anger.png";
import anxiety from "../assets/icons/anxiety.png";
import dependent from "../assets/icons/dependent.png";
import disconnected from "../assets/icons/disconnected.png";
import fear from "../assets/icons/fear.png";
import independent from "../assets/icons/independent.png";
import interdependent from "../assets/icons/interdependent.png";
import joy from "../assets/icons/joy.png";
import love from "../assets/icons/love.png";
import sadness from "../assets/icons/sadness.png";
import shame from "../assets/icons/shame.png";

const EqqoIcon = ({ height, icon, width }) => {
  const icons = {
    anger,
    anxiety,
    dependent,
    disconnected,
    fear,
    independent,
    interdependent,
    joy,
    love,
    sadness,
    shame
  };

  return (
    <img
        alt=""
        data-name={icon}
        height={height}
        src={icons[icon]}
        width={width}
    />
  );
};

EqqoIcon.defaultProps = {
  height: 40,
  width: 40
};

EqqoIcon.propTypes = {
  height: PropTypes.number,
	icon: PropTypes.string.isRequired,
  width: PropTypes.number
};

export default EqqoIcon;
