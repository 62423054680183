/* eslint-disable max-len */

import { Col, Container, Row, Stack } from "react-bootstrap";
import Helmet from "react-helmet";
import PaymentForm from "./PaymentForm";

const Payment = () => (
	<Row as="main" className="sign-up">
		<Helmet>
			<title>
				{"Account Payment"}
			</title>
		</Helmet>

		<Col className="create-account flow" fluid>
			<img
				alt="LOGO" className="logo" height="704" src="/eqqo_logo.png"
				width="703"
			/>
			<Container className="bg-white flow">
				<Stack className="justify-content-center" direction="horizontal" gap={3}>
					<div className="p-2">Account</div>
					<div className="p-2">Questions</div>
					<div className="p-2">Payment</div>
				</Stack>
				<h1>Account Payment</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</p>
				<h2>Subtotal</h2>
				<p className="total-due">$195.00</p>
				<hr />
				<PaymentForm />
			</Container>
		</Col>
	</Row>
);

export default Payment;
