import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import ACFCheckboxes from "../components/ACForm/ACFCheckboxes";
import ACFSelectField from "../components/ACForm/ACFSelectField";
import ACFTextField from "../components/ACForm/ACFTextField";

const EqqoFormField = ({
	className,
	defaultValue,
	field,
	labelClasses,
	name,
	onChange
}) => {

	const fieldCols = (width) => parseInt(12 * width / 100, 10);

	const renderField = (fieldSettings) => {
		switch (fieldSettings.type) {
		case "select":
			return (
				<Form.Group controlId={name}>
					<Form.Label className={labelClasses}>{field.label}</Form.Label>
					<ACFSelectField defaultValue={defaultValue} field={fieldSettings} name={name} onChange={onChange} />
					{!!field.instructions && <Form.Text className="d-block">{field.instructions}</Form.Text>}
				</Form.Group>
			);
		case "checkbox":
			return (
				<ACFCheckboxes
					defaultValue={defaultValue}
					field={field}
					labelClasses={labelClasses}
					name={name}
					onChange={onChange}
				/>);
		default:
			return (
				<Form.Group controlId={name}>
					<Form.Label className={labelClasses}>{field.label}</Form.Label>
					<ACFTextField defaultValue={defaultValue} field={fieldSettings} name={name} onChange={onChange} />
					{!!field.instructions && <Form.Text className="d-block">{field.instructions}</Form.Text>}
				</Form.Group>
			);
		}
	};

	return (
		<Col className={`${className} align-content-center`} md={fieldCols(field.wrapper.width || 100)}>
			{renderField(field)}
		</Col>
	);
};

EqqoFormField.propTypes = {
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	field: PropTypes.shape({
		instructions: PropTypes.string,
		label: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		required: PropTypes.number,
		type: PropTypes.string,
		wrapper: PropTypes.shape({
			width: PropTypes.string
		}).isRequired
	}).isRequired,
	labelClasses: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func
};

EqqoFormField.defaultProps = {
	className: "mb-4",
	defaultValue: null,
	labelClasses: "",
	name: null,
	onChange: null
};

export default EqqoFormField;
