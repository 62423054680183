/* eslint-disable react/jsx-no-bind */
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import PropTypes from "prop-types";

const AssessmentQuestionScale = ({ id, responseLabels, updateQuestions, value }) => (
	<ToggleButtonGroup
		className="d-flex"
		name={`options-${id}`}
		onChange={updateQuestions}
		questionid={id}
		type="radio"
		value={value}
	>
		{
			responseLabels.map((responseLabel) => (
				<ToggleButton id={`tbg-btn-${id}-${responseLabel.rating}`} key={Number(responseLabel.rating)} value={Number(responseLabel.rating)} variant="primary">
					{responseLabel.rating}
				</ToggleButton>
			))
		}
	</ToggleButtonGroup>
);

AssessmentQuestionScale.propTypes = {
	id: PropTypes.number.isRequired,
	responseLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateQuestions: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired
};

export default AssessmentQuestionScale;
