import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Toast } from "react-bootstrap";
import { isString } from "lodash";

const dismissDelay = 10000;
const iconClasses = "i i-2x me-2";

const NotificationToast = ({
	onClose,
	notification
}) => {

	const handleHide = useCallback(() => {
		onClose(notification.id);
	}, []);

	const icon = useMemo(() => {
		const toastType = notification.type || "info";

		switch (toastType) {
		case "success":
			return <CheckCircleIcon className={iconClasses} />;
		case "danger":
			return <XCircleIcon className={iconClasses} />;
		default:
			return <InformationCircleIcon className={iconClasses} />;
		}
	}, [notification.type]);

	return (
		<Toast
			autohide
			bg={notification.type || "info"}
			delay={dismissDelay}
			onClose={handleHide}
		>
			{notification.header && <Toast.Header>{notification.header}</Toast.Header>}
			{notification.message && <Toast.Body><span>{icon}{notification.message}</span></Toast.Body>}
			{notification.body && <Toast.Body><span>{icon}{notification.body}</span></Toast.Body>}
			{isString(notification) && <Toast.Body><span>{icon}{notification}</span></Toast.Body>}
		</Toast>
	);
};

NotificationToast.propTypes = {
	notification: PropTypes.shape({
		body: PropTypes.element,
		header: PropTypes.string,
		id: PropTypes.string,
		message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		type: PropTypes.string
	}).isRequired,
	onClose: PropTypes.func.isRequired
};

export default NotificationToast;
