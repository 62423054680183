import AssessmentQuestionScale from "./AssessmentQuestionScale";
import PropTypes from "prop-types";

const AssessmentQuestion = ({ question, responseLabels }) => (
	<div className="assessment-question">
		<p data-question-id={question.id}>{question.text}</p>
		<AssessmentQuestionScale id={question.id} responseLabels={responseLabels} />
	</div>
);

AssessmentQuestion.propTypes = {
	question: PropTypes.shape({
		id: PropTypes.number.isRequired,
		response: PropTypes.number,
		text: PropTypes.string.isRequired
	}).isRequired,
	responseLabels: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AssessmentQuestion;
