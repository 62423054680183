import { createContext, useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import NotificationToast from "./NotificationToast";
import NotificationsContainer from "./NotificationsContainer";

const NotificationsContext = createContext(null);

export const NotifcationsContextProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);

	const addNotification = useCallback((newNotification) => setNotifications((prev) => [
		...prev, {
			id: uuidv4(),
			...newNotification
		}
	]), []);

	const contextProps = useMemo(() => ({
		addNotification,
		notifications
	}), [notifications]);

	const handleHide = useCallback((id) => setNotifications((prev) => prev.filter((notification) => notification.id !== id)), []);

	return (
		<NotificationsContext.Provider value={contextProps}>
			{children}
			<NotificationsContainer>
				{notifications.map((notification) => <NotificationToast key={notification.id} notification={notification} onClose={handleHide} />)}
			</NotificationsContainer>
		</NotificationsContext.Provider>
	);
};

NotifcationsContextProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export const useNotifications = () => useContext(NotificationsContext);
