/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len, arrow-body-style, no-unused-vars */
import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { Col, Row } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";

const EqqoPieChart = ({ scores }) => {
	ChartJS.register(ArcElement, Tooltip);

	const chartData = scores;

	const options = {
		responsive: true
	};

	const data = {
		datasets: [
			{
				backgroundColor: ["#C4327C", "#293B47", "#EF6A17", "#0D213C"],
				data: chartData,
				datalabels: {
					align: "center",
					anchor: "center",
					color: "#FFFFFF",
					font: {
						size: 13,
						weight: 600
					},
					formatter (value, context) {
						return `${value}%`;
					}
				},
				label: "Scores"
			}
		]
	};

	return (
		<Row className="eqqo-pie-chart | py-3">
			<Col className="chart">
				<Pie data={data} options={options} />
			</Col>
		</Row>
	);
};

EqqoPieChart.propTypes = {
	scores: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default EqqoPieChart;
