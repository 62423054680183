/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable id-length, func-names, no-unused-vars, no-console, max-statements, prefer-template, react/jsx-sort-props, multiline-comment-style, capitalized-comments, consistent-return, no-warning-comments */

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale } from "chart.js";
import { Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import EqqoIcon from "../icons/EqqoIcon";
import PropTypes from "prop-types";

const EqqoScoreBar = ({ icon, label, scores }) => {

	// const legendID = legend;
	const chartData = scores;

	ChartJS.register(
		CategoryScale,
		ChartDataLabels,
		Legend,
		LinearScale,
		BarElement
	);

	const options = {
		elements: {
			bar: {
				barThickness: 0.5,
				borderRadius: 4,
				borderSkipped: false,
				borderWidth: 0
			}
		},
		indexAxis: "y",
		layout: {
			padding: {
				bottom: 2,
				left: 16,
				right: 16,
				top: 2
			}
		},
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			}
		},
		responsive: true,
		scales: {
			x: {
				display: false,
				// TODO: this scale maybe needs to be passed in dynamically
				suggestedMax: 30,
				suggestedMin: 0
			},
			y: {
				beginAtZero: true,
				display: false
			}
		}
	};

	const data = {
		datasets: [
			{
				backgroundColor: ["#C4327C", "#E3E4E7"],
				data: chartData,
				datalabels: {
					align: "end",
					anchor: "end",
					color: ["#C4327C", "#0D213C"],
					font: {
						size: 13,
						weight: 500
					},
					formatter (value, context) {
						return `${value}%`;
					},
					offset: 4,
					weight: "bold"
				}
			}
		],
		labels: ["Your Score", "Population Score"]
	};

	return (
		<Row className="eqqo-score-bar | border-bottom py-3">
			<Col className="align-content-center text-center" xs={3}>
				<EqqoIcon icon={icon} />
				<p className="fw-semibold mt-2">{label}</p>
			</Col>
			<Col className="chart" xs={9}>
				<Bar data={data} options={options} />
			</Col>
		</Row>
	);
};

EqqoScoreBar.propTypes = {
	icon: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	scores: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default EqqoScoreBar;
