/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable id-length, func-names, no-unused-vars, no-console, max-statements, prefer-template, react/jsx-sort-props, multiline-comment-style, capitalized-comments, consistent-return, no-warning-comments */

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale } from "chart.js";
import { Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import ChartAnnotations from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";

const EqqoStackedBar = ({ scores }) => {

	const chartData = scores;

	ChartJS.register(
		BarElement,
		CategoryScale,
		ChartAnnotations,
		ChartDataLabels,
		Legend,
		LinearScale
	);

	const options = {
		plugins: {
			annotation: {
				annotations: {
					line1: {
						borderColor: "#0D213C",
						borderDash: [4, 4],
						borderWidth: 2,
						type: "line",
						yMax: 25,
						yMin: 25
					}
				}
			},
			datalabels: {
				align: "center",
				anchor: "center",
				font: {
					size: 13,
					weight: 600
				},
				formatter (value, context) {
					return `${value}%`;
				}
			},
			legend: {
				display: false
			}
		},
		responsive: true,
		scales: {
			x: {
				display: false,
				stack: true
			},
			y: {
				display: false,
				stacked: true,
				// TODO: this scale maybe needs to be passed in dynamically
				suggestedMax: 100,
				suggestedMin: 0
			}
		}
	};

	const data = {
		datasets: [
			{
				backgroundColor: "#E3E4E7",
				borderRadius: {
					bottomLeft: 8,
					bottomRight: 8,
					topLeft: 0,
					topRight: 0
				},
				borderSkipped: false,
				data: [27.6],
				datalabels: {
					color: "#0D213C"
				},
				label: "Population Score",
				stack: "Stack"
			},
			{
				backgroundColor: "#C4327C",
				borderRadius: {
					bottomLeft: 0,
					bottomRight: 0,
					topLeft: 8,
					topRight: 8
				},
				borderSkipped: false,
				data: [72.4],
				datalabels: {
					color: "#FFFFFF"
				},
				label: "Your Score",
				stack: "Stack"
			}
		],
		labels: ["Scores"]
	};

	return (
		<Row className="eqqo-stacked-bar | border-bottom py-3">
			<Col className="chart">
				<Bar data={data} options={options} />
			</Col>
		</Row>
	);
};

EqqoStackedBar.propTypes = {
	scores: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default EqqoStackedBar;
