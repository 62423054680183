/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable multiline-ternary */
import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ACFCheckboxes = ({
	defaultValue,
	field,
	labelClasses,
	name,
	onChange
}) => {

	const [value, setValue] = useState(defaultValue || []);

	const labelArray = Object.values(field.choices);
	const valueArray = Object.keys(field.choices);
	// eslint-disable-next-line no-magic-numbers
	const hasValues = Boolean(valueArray[0]);

	const handleChange = useCallback(({ target }) => {
		const idx = value.indexOf(target.value);
		const newValue = value;
		if (idx === -1) {
			newValue.push(target.value);
		} else {
			newValue.splice(idx, 1);
		}
		setValue(newValue);
		onChange({
			target: {
				name,
				value: newValue
			}
		});
	});

	return (
		<fieldset>
			<Form.Label className={`h6 ${labelClasses}`}>{field.label}</Form.Label>
			{!!field.instructions && <Form.Text className="d-block mb-3">{field.instructions}</Form.Text>}
			{
				labelArray.map((choice, idx) => (
					<Form.Check
						defaultChecked={value.indexOf(hasValues ? valueArray[idx] : choice) !== -1}
						id={`${field.name}-${idx}`}
						key={hasValues ? valueArray[idx] : choice}
						label={choice}
						name={name}
						onChange={handleChange}
						value={hasValues ? valueArray[idx] : choice}
					/>
				))
			}
		</fieldset>
	);
};

ACFCheckboxes.propTypes = {
	defaultValue: PropTypes.arrayOf(PropTypes.string),
	field: PropTypes.shape({
		choices: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})]),
		instructions: PropTypes.string,
		label: PropTypes.string.isRequired,
		name: PropTypes.string
	}).isRequired,
	labelClasses: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

ACFCheckboxes.defaultProps = {
	defaultValue: [],
	labelClasses: "",
	onChange: null
};

export default ACFCheckboxes;
