/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import CheckmarkIcon from "../../icons/CheckmarkIcon";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const CreateAccount = () => {
	const { state } = useLocation();

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Create Your EQQO Account"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid>
				<img alt="LOGO" className="logo" height="704" src="/eqqo_logo.png"
					width="703"
				/>
				<Container className="bg-white flow">
					<Stack className="justify-content-center" direction="horizontal" gap={3}>
						<div className="p-2">Account</div>
						<div className="p-2">Questions</div>
						<div className="p-2">Payment</div>
					</Stack>
					<h1>Create Your EQQO Account</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</p>
					<Form>
						<Form.Label>Email Address</Form.Label>
						<Form.Control type="email" />
						<Button className="btn-gradient mt-4 w-100" href="/sign-up/account/success">
							Create Account
						</Button>
					</Form>
					<p className="labeled-divider">OR</p>
					<Button className="w-100" variant="outline btn-outline-eqqo-purple">
						Sign Up with Google
					</Button>
					<p>By creating an account, you agree to EQQO's Privacy Policy & Terms of Use</p>
					<p>Already have an account?</p>
					<a href="/sign-in">Sign In</a>
				</Container>
			</Col>
		</Row>
	);
};

export default CreateAccount;
