import { Col, FormCheck, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import PaymentHistory from "./PaymentHistory";

const MyAccount = () => (
	<div className="mt-4">
		<Helmet>
			<title>{"My EQQO Account"}</title>
		</Helmet>

		<h2>My Account</h2>
		<Row className="py-4">
			{/* Terrible markup follows, but for visual demonstration purposes... */}
			<Col md={6}>
				<h4>Name</h4>
				<p>Chris Yoko</p>
			</Col>
			<Col md={6}>
				<h4>Email</h4>
				<p>chris@yokoco.com</p>
			</Col>
			<Col md={6}>
				<h4>Newsletter</h4>
				<FormCheck label="Subscribe to our awesome newsletter" type="switch" />
			</Col>
		</Row>
		<Row>
			<PaymentHistory />
		</Row>
	</div>
);

export default MyAccount;
