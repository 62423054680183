import { Button, Modal } from "react-bootstrap";
import { clone, isArray } from "lodash";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import { getAnnouncementQuery } from "./announcementQuery";
import { useAuth } from "../services/wp.auth.service";

const AnnouncementsContext = createContext(null);

const dismissedLSKey = "liaDismissedAnnouncements";

export const AnnouncementsContextProvider = ({ children }) => {
	const { user } = useAuth();
	const [announcement, setAnnouncement] = useState(null);

	const dismissedAnnouncements = useMemo(() => {
		try {
			return JSON.parse(window.localStorage.getItem(dismissedLSKey));
		} catch {
			window.localStorage.removeItem(dismissedLSKey);
		}
		return [];
	}, []);

	const {
		data
	} = getAnnouncementQuery(useQuery, user?.roles, dismissedAnnouncements || []);

	useEffect(() => {
		if (data?.databaseId) {
			if (!dismissedAnnouncements || dismissedAnnouncements.indexOf(data.databaseId) === -1) {
				setAnnouncement(data);
			}
		}
	}, [data]);

	const onClose = useCallback(() => {
		let dAnnouncements = clone(dismissedAnnouncements);
		if (isArray(dismissedAnnouncements)) {
			dAnnouncements.push(data.databaseId);
		} else {
			dAnnouncements = [data.databaseId];
		}
		window.localStorage.setItem(dismissedLSKey, JSON.stringify(dAnnouncements));
		setAnnouncement(null);
	}, [setAnnouncement, data]);

	return (
		<AnnouncementsContext.Provider>
			{children}
			<Modal centered show={!!announcement} size="lg">
				<Modal.Body className="p-4">
					<div className="announcement-body" dangerouslySetInnerHTML={{ __html: announcement?.content }} />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={onClose}>Got it!</Button>
				</Modal.Footer>
			</Modal>
		</AnnouncementsContext.Provider>
	);
};

AnnouncementsContextProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired
};
