/* eslint-disable */
import AssessmentData from "../../liaStatements/statements_en.json";
import AssessmentQuestion from "./AssessmentQuestion";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import EqqoVideo from "./EqqoVideo";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

const Assessment = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const scenarioData = AssessmentData.scenarios[id];
	const questionArray = Object.entries(scenarioData.questions).map((question) => (
		{
			id: question[1].statement_id,
			response: null,
			text: question[1].statement
		}
	));
	const responseLabels = AssessmentData.responseLabels;
	
	// TODO: a better way to type the ID param from the URL rather than convert for every operation here?
	const backUrl = parseInt(id) <= 1 ? `/profile/assessment/instructions` : `/profile/assessment/${ parseInt(id) - 1 }`;
	const forwardUrl = parseInt(id) >= 8 ? `/report/landing` : `/profile/assessment/${ parseInt(id) + 1 }`;

	// video callbacks and state... should maybe be moved to EqqoVideo component?
	const [canAnswer, setCanAnswer] = useState(false); // will be used to control if questions can be answered or not (must watch video first)
	
	const minTime = 10000; // minimum time that needs to elapse before user can answer questions, helps ensure they watch the video
	const [timeoutMinTime, setTimeoutMinTime] = useState(false);
	useTimer({
		autoStart: true,
		expiryTimestamp: new Date().getTime() + minTime,
		onExpire: () => {
			setTimeoutMinTime(true);
		}
	});

	const [videoFinished, setVideoFinished] = useState(false); // track if video is finished, i.e. has been watched
	const onProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);

	useEffect(() => {
		if (videoFinished && timeoutMinTime && !canAnswer) {
			setCanAnswer(true); // enable answering questions
		}
	}, [videoFinished, timeoutMinTime]);

	return (
		<div className="assessment-view">
			<h2>Scenario {id}</h2>
			<Row className="assessment-step-content">
				<Col md={6}>
					<h3>Your Role:</h3>
					<p>{scenarioData.scenario.roles}</p>
				</Col>
				<Col>
					<h3>The Situation:</h3>
					<p>{scenarioData.scenario.situation}</p>
				</Col>
				
				<Container className="my-5 video" data-video-id={scenarioData.videoId}>
					<EqqoVideo
						className="d-block w-100 mw-100"
						config={{
							wistia: {
								options: {
									playbackRateControl: false,
									playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
									playerColor: "000000",
									plugins: {
										"captions-v1": {
											onByDefault: true
										}
									},
									preload: true,
									videoFoam: true
								}
							}
						}}
						onProgress={onProgress}
						style={{ aspectRatio: "16 / 9" }}
						url={`https://fast.wistia.net/embed/iframe/${scenarioData.videoId}`}
					/>
				</Container>

				<Stack gap={4}>
					<h3>Questions</h3>
					{questionArray.map((q) => {
						return <AssessmentQuestion key={q.id} question={q} responseLabels={responseLabels} />
					})}
				</Stack>

				<div className="d-flex justify-content-between my-5">
					<Button href={backUrl} variant="primary-outline">Back</Button>
					<Button href={forwardUrl}>Next</Button>
				</div>
			</Row>
		</div>
	);
};

export default Assessment;
