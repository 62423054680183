/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Container, Nav } from "react-bootstrap";
import { DocumentChartBarIcon, EnvelopeIcon, HomeIcon, MagnifyingGlassIcon, UserCircleIcon } from "@heroicons/react/20/solid";

const ProfileReportNav = () => (
	<Container className="profile-report__nav">
		<Nav as="nav" className="align-items-center flex-nowrap fs-18 justify-content-between py-2" defaultActiveKey="/profile/report">
			<Nav.Item>
				<Nav.Link className="align-items-center d-flex flex-column gap-2 text-text-color-light" href="/profile">
					<HomeIcon height={20} width={20} />
					Home
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link className="align-items-center d-flex flex-column gap-2 text-text-color-light" eventKey="/profile/search" href="/profile/search">
					<MagnifyingGlassIcon height={20} width={20} />
					Search
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link className="btn btn-gradient d-flex flex-column rounded-circle text-text-color-light" eventKey="/profile/search" href="/profile/search">
					<DocumentChartBarIcon height={36} width={36} />
					<span>Report</span>
				</Nav.Link>
			</Nav.Item>
			<Nav.Item className="">
				<Nav.Link className="align-items-center d-flex flex-column gap-2 text-text-color-light" eventKey="/logout" href="/logout">
					<EnvelopeIcon height={20} width={20} />
					Mail
				</Nav.Link>
			</Nav.Item>
			<Nav.Item className="">
				<Nav.Link className="align-items-center d-flex flex-column gap-2 text-text-color-light" eventKey="/logout" href="/logout">
					<UserCircleIcon height={20} width={20} />
					Profile
				</Nav.Link>
			</Nav.Item>
		</Nav>
	</Container>
);

export default ProfileReportNav;
