/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */

import { ArrowLeftCircleIcon, DocumentIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Container, Nav } from "react-bootstrap";

const ProfileNav = () => {
	return (
		<Container className="profile__nav">
			<img alt="LOGO" className="logo" height="704" src="/eqqo_logo.png"
				width="703"
			/>
			<Nav as="nav" className="flex-column fs-18 pt-4" defaultActiveKey="/profile">
				<Nav.Item>
					<Nav.Link className="text-text-color-light" href="/profile/assessment/instructions">
						<DocumentIcon />
						Instructions
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link className="text-text-color-light" eventKey="/profile/help" href="/profile/help">
						<QuestionMarkCircleIcon />
						Help
					</Nav.Link>
				</Nav.Item>
				<Nav.Item className="mt-auto">
					<Nav.Link className="text-text-color-light" eventKey="/logout" href="/logout">
						<ArrowLeftCircleIcon height={24} width={24} />
						Logout
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</Container>
	);
};

export default ProfileNav;
