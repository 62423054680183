import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

const Loader = ({ fullscreen }) => {

	const loaderStyles = fullscreen
		? {
			height: "100vh",
			margin: "0 auto",
			width: "200px"
		}
		: {
			height: "200px",
			margin: "0 auto",
			width: "200px"
		};

	return (
		<div
			className="d-flex align-items-center text-center justify-content-center"
			style={loaderStyles}
		>
			<div style={{
				margin: "0 auto",
				width: "200px"
			}}>
				<Spinner animation="border" className="align-self-center" role="status">
					<span className="visually-hidden">
						Loading...
					</span>
				</Spinner>
			</div>
		</div>
	);

};

Loader.propTypes = {
	fullscreen: PropTypes.bool
};

Loader.defaultProps = {
	fullscreen: true
};

export default Loader;
