import { ToastContainer } from "react-bootstrap";
import { createPortal } from "react-dom";

// eslint-disable-next-line function-paren-newline
const NotificationsContainer = ({ children }) => createPortal(
	<ToastContainer className="p-3 position-fixed" id="site-notifications" position="bottom-end">
		{children}
	</ToastContainer>,
	document.getElementById("lia-notifications")
);

export default NotificationsContainer;
