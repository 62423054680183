import { Accordion, Col, Container, Row, Stack } from "react-bootstrap";
import EqqoGauge from "./EqqoGauge";
import EqqoIcon from "../icons/EqqoIcon";
import EqqoPieChart from "./EqqoPieChart";
import EqqoScoreBar from "./EqqoScoreBar";
import EqqoStackedBar from "./EqqoStackedBar";
import Helmet from "react-helmet";
import ProfileHeader from "./ReportHeader";
import ProfileReportNav from "./ReportNav";

const Report = () => (
	<Row as="main" className="profile profile__report | bg-eqqo-blue-white flex-dir-col">
		<Helmet>
			<title>
				{"EQQO Profile Report"}
			</title>
		</Helmet>
		<ProfileHeader />
		<Col className="align-items-center d-flex flex-column gap-4 pb-5">
			<h2 className="mt-4">Your Leading Emotion</h2>
			<p>Your responses indicate that difficult work situations often generate a lot of joy for you.</p>
			<Container className="align-items-center border d-flex flex-column rounded-3 shadow">
				<EqqoIcon height={96} icon="joy" width={96} />
				<h3>Joy</h3>
				<Stack className="justify-content-between px-5" direction="horizontal" gap="4">
					<div className="align-items-center d-flex flex-column">
						<p className="fw-bold my-0">24.4</p>
						<p className="fw-light">Score</p>
					</div>
					<div className="align-items-center d-flex flex-column">
						<p className="fw-bold my-0">20.1</p>
						<p className="fw-light">Population</p>
					</div>
					<div className="align-items-center d-flex flex-column">
						<p className="fw-bold my-0">85.8%</p>
						<p className="fw-light">Percentile</p>
					</div>
				</Stack>
			</Container>
			<h2 className="mt-4">Your Top Emotions</h2>
			<p>These graphs show how often you accessed each emotion compared to the general population who has taken this assessment.</p>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<EqqoScoreBar icon="joy" label="Joy" scores={[22.1, 17.5]} />
				<EqqoScoreBar icon="anger" label="Anger" scores={[19.4, 13.7]} />
				<EqqoScoreBar icon="love" label="Love" scores={[12.3, 17.3]} />
				<EqqoScoreBar icon="shame" label="Shame" scores={[8.1, 13.4]} />
				<EqqoScoreBar icon="sadness" label="Sadness" scores={[16.2, 9.9]} />
				<EqqoScoreBar icon="anxiety" label="Anxiety" scores={[10.1, 5.3]} />
				<EqqoScoreBar icon="fear" label="Fear" scores={[3.9, 8.8]} />
				<div className="chart-legend | d-flex flex-wrap gap-4 justify-content-end mt-3" id="top-emotion-legend">
					<ul>
						<li style={{ "--legend-item-color": "#C4327C" }}>Your Score</li>
						<li style={{ "--legend-item-color": "#E3E4E7" }}>Population Score</li>
					</ul>
				</div>
			</Container>
			<Accordion className="shadow w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header>What Does This Mean?</Accordion.Header>
					<Accordion.Body>
						Some explanation for what this means.
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<h2 className="mt-4">Your Percentile Rank</h2>
			<p>This is how you compare to the general population.</p>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<EqqoScoreBar icon="joy" label="Joy" scores={[3.1, 7.9]} />
				<EqqoScoreBar icon="anger" label="Anger" scores={[1.2, 13.4]} />
				<EqqoScoreBar icon="love" label="Love" scores={[11.2, 4.2]} />
				<EqqoScoreBar icon="shame" label="Shame" scores={[13.7, 4.8]} />
				<EqqoScoreBar icon="sadness" label="Sadness" scores={[18.2, 24.9]} />
				<EqqoScoreBar icon="anxiety" label="Anxiety" scores={[23.1, 12.7]} />
				<EqqoScoreBar icon="fear" label="Fear" scores={[4.1, 14.9]} />
				<div className="chart-legend | d-flex flex-wrap gap-4 justify-content-end mt-3" id="percentile-rank-legend">
					<ul>
						<li style={{ "--legend-item-color": "#C4327C" }}>Your Score</li>
						<li style={{ "--legend-item-color": "#E3E4E7" }}>Population Score</li>
					</ul>
				</div>
			</Container>
			<Accordion className="shadow w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header>What Does This Mean?</Accordion.Header>
					<Accordion.Body>
						Some explanation for what this means.
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<h2 className="mt-4">Positive-Negative Orientation</h2>
			<p>This shows how quickly you interpret a difficult work situation as negative or positive.</p>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<EqqoStackedBar scores={[72.14, 27.86]} />
				<div className="chart-legend | d-flex flex-wrap gap-4 justify-content-end mt-3" id="top-emotion-legend">
					<ul>
						<li style={{ "--legend-item-color": "#C4327C" }}>Your Score</li>
						<li style={{ "--legend-item-color": "#E3E4E7" }}>Population Score</li>
						<li data-type="line" style={{ "--legend-item-color": "#0D213C" }}>Ideal</li>
					</ul>
				</div>
			</Container>
			<Accordion className="shadow w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header>What Does This Mean?</Accordion.Header>
					<Accordion.Body>
						Some explanation for what this means.
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<h2 className="mt-4">Self-Other Orientation</h2>
			<p>This shows how easily you focus on either yourself or the other person in a difficult work situation.</p>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<div className="chart">
					<EqqoGauge />
				</div>
			</Container>
			<Accordion className="shadow w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header>What Does This Mean?</Accordion.Header>
					<Accordion.Body>
						Some explanation for what this means.
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<h2 className="mt-4">Decision Drivers</h2>
			<p>This indicates what you’re most likely to turn to in times of stress.</p>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<EqqoPieChart scores={[34.3, 26.76, 38.94]} />
				<div className="chart-legend | d-flex flex-wrap gap-4 justify-content-end mt-3">
					<ul>
						<li style={{ "--legend-item-color": "#C4327C" }}>Thoughts</li>
						<li style={{ "--legend-item-color": "#293B47" }}>Wants</li>
						<li style={{ "--legend-item-color": "#EF6A17" }}>Feelings</li>
					</ul>
				</div>
			</Container>
			<Accordion className="shadow w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header>What Does This Mean?</Accordion.Header>
					<Accordion.Body>
						Some explanation for what this means.
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<h2 className="mt-4">Relationship Strategies</h2>
			<p>This shows how you manage yourself when difficult situation arise in the workplace.</p>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<EqqoPieChart scores={[24.12, 16.29, 12.89, 36.39]} />
				<div className="chart-legend | d-flex flex-wrap gap-4 justify-content-end mt-3">
					<ul>
						<li style={{ "--legend-item-color": "#C4327C" }}>Interdependent</li>
						<li style={{ "--legend-item-color": "#293B47" }}>Dependent</li>
						<li style={{ "--legend-item-color": "#EF6A17" }}>Independent</li>
						<li style={{ "--legend-item-color": "#0D213C" }}>Disconnected</li>
					</ul>
				</div>
			</Container>
			<Container className="border d-flex flex-column rounded-3 shadow">
				<EqqoScoreBar icon="interdependent" label="Interdependent" scores={[24.4, 16.5]} />
				<EqqoScoreBar icon="dependent" label="Dependent" scores={[12.4, 8.2]} />
				<EqqoScoreBar icon="independent" label="Independent" scores={[12.6, 10.2]} />
				<EqqoScoreBar icon="disconnected" label="Disconnected" scores={[5.5, 6.8]} />
				<div className="chart-legend | d-flex flex-wrap gap-4 justify-content-end mt-3" id="relationship-strategy-legend" />
			</Container>
			<Accordion className="shadow w-100">
				<Accordion.Item eventKey="0">
					<Accordion.Header>What Does This Mean?</Accordion.Header>
					<Accordion.Body>
						Some explanation for what this means.
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Col>
		<ProfileReportNav />
	</Row>
);

export default Report;
