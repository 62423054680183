import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ACFTextField = ({ defaultValue, field, name, onChange }) => (
	<Form.Control
		defaultValue={defaultValue}
		name={name}
		onChange={onChange}
		placeholder={field.placeholder || field.label}
		required={Boolean(field.required)}
	/>
);

ACFTextField.propTypes = {
	defaultValue: PropTypes.string,
	field: PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
		placeholder: PropTypes.string,
		required: PropTypes.number
	}).isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

ACFTextField.defaultProps = {
	defaultValue: null,
	onChange: null
};

export default ACFTextField;
