/* eslint-disable max-len, no-unused-vars */

import { Button, Container, Form } from "react-bootstrap";
import { useCallback, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import EqqoVideo from "./EqqoVideo";

const Instructions = () => {

	const instructionVideoID = "0p5cz2somv";
	// Track if video is finished, i.e. has been watched
	const [videoFinished, setVideoFinished] = useState(false);
	const onProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);

	return (
		<div className="profile__instructions mt-4">
			<h2>Getting Started</h2>
			<p>Hi Chris! Please watch the video for instructions</p>
			<Container className="video">
				<EqqoVideo
					className="d-block w-100 mw-100"
					config={{
						wistia: {
							options: {
								playbackRateControl: false,
								playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
								playerColor: "000000",
								plugins: {
									"captions-v1": {
										onByDefault: true
									}
								},
								preload: true,
								videoFoam: true
							}
						}
					}}
					onProgress={onProgress}
					style={{ aspectRatio: "16 / 9" }}
					url={`https://fast.wistia.net/embed/iframe/${instructionVideoID}`}
				/>
			</Container>
			<Form className="bg-eqqo-blue-white p-3">
				<Form.Check
					className="fs-18"
					id="instruction-confirm"
					label="I have watched and/or read the instructions and I understand that I am to respond not in my current professional role, but in the roles suggested before each video."
					name="instructions-confirmation"
				/>
			</Form>
			<Button className="w-100" disabled={!videoFinished} href="/profile/assessment/1">Start Assessment <ArrowRightIcon height={24} width={24} /></Button>
			<Button className="mt-4 w-100" href="/report/landing" variant="primary-outline">View Report <ArrowRightIcon height={24} width={24} /></Button>
		</div>
	);
};

export default Instructions;
