/* eslint-disable capitalized-comments */

import "./index.scss";
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import Eqqo from "./eqqo/Eqqo";
import React from "react";
import ReactDOM from "react-dom";
import ScrollToTop from "./components/ScrollToTop";

if (process.env.NODE_ENV !== "production") {
	const axe = require("@axe-core/react");
	axe(React, ReactDOM, 1000); // eslint-disable-line no-magic-numbers
}

/*
 * DSN for Sentry is defined via a secret in the Github repo.
 * It is passed as env variable during deployment via
 * the the Kinsta CD action.
 */
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 0.5
	});
}

if (process.env.REACT_APP_EQQO) {
	ReactDOM.render(
		<React.StrictMode>
			<BrowserRouter>
				<ScrollToTop />
				<Eqqo />
			</BrowserRouter>
		</React.StrictMode>,
		document.getElementById("root")
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<BrowserRouter>
				<ScrollToTop />
				{/* <App /> */}
			</BrowserRouter>
		</React.StrictMode>,
		document.getElementById("root")
	);
}

serviceWorkerRegistration.register();
