import Helmet from "react-helmet";

const Help = () => (
	<div className="mt-4">
		<Helmet>
			<title>{"EQQO Help"}</title>
		</Helmet>

		<h2>Help</h2>
		<div>
			<p>Need help? Don&apos;t worry, we got you.</p>
		</div>
	</div>
);

export default Help;
