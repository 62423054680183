import { Button, Form } from "react-bootstrap";
import EqqoFormField from "../EqqoFormField";
import form from "../../acf-json/sign-up-form.json";

const QuestionnaireForm = () => (
	<Form className="flow row" name={form.title}>
		<h2>Required</h2>
		{form.fields.map((field) => <EqqoFormField field={field} key={field.key} />)}
		<Button className="w-100" href="/sign-up/pay" variant="primary">Continue</Button>
	</Form>
);

export default QuestionnaireForm;
