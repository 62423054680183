/* eslint-disable react/no-unescaped-entities */

import { Button, Col, Form, Row } from "react-bootstrap";

const PaymentForm = () => (
	<Form className="flow">
		<h2>Card Information</h2>
		<Row>
			<Col>
				<Form.Label>Name on Card</Form.Label>
				<Form.Control type="text" />
			</Col>
		</Row>
		<Row>
			<Col>
				<Form.Label>Card Number</Form.Label>
				<Form.Control type="text" />
			</Col>
		</Row>
		<Row>
			<Col>
				<Form.Label>MM / YY</Form.Label>
				<Form.Control type="text" />
			</Col>
			<Col>
				<Form.Label>CVC</Form.Label>
				<Form.Control type="text" />
			</Col>
		</Row>
		<hr />
		<h2>Billing Address</h2>
		<Row>
			<Col>
				<Form.Label>Country</Form.Label>
				<Form.Select>
					<option>Choose One</option>
					<option>'Merica</option>
				</Form.Select>
			</Col>
		</Row>
		<Row>
			<Col>
				<Form.Label>Street Address</Form.Label>
				<Form.Control type="text" />
			</Col>
		</Row>
		<Row>
			<Col>
				<Form.Label>City</Form.Label>
				<Form.Control type="text" />
			</Col>
			<Col>
				<Form.Label>State</Form.Label>
				<Form.Control type="text" />
			</Col>
			<Col>
				<Form.Label>Zip</Form.Label>
				<Form.Control type="text" />
			</Col>
		</Row>
		<Button className="w-100" href="/sign-up/pay/success" type="submit" variant="primary">Confirm</Button>
	</Form>
);

export default PaymentForm;
