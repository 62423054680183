import { ChevronLeftIcon, UserCircleIcon } from "@heroicons/react/24/solid";

const ReportHeader = () => (
	<header className="profile__header align-items-center d-flex flex-nowrap justify-content-between py-4 sticky-top">
		<a href="/profile/assessment/instructions"><ChevronLeftIcon height={24} width={24} /></a>
		<h1 className="my-0">EQQO Profile</h1>
		<a href="/profile/account"><UserCircleIcon height={24} width={24} /></a>
	</header>
);

export default ReportHeader;
